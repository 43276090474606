<template>
  <v-bottom-navigation
    color="success"
    fixed
    class="d-xl-none d-lg-none d-md-none"
    grow
    v-if="is_authenticated"
  >
    <v-btn
      icon
      class="bottom-nav-btn"
      :to="route.to"
      v-for="route in routes"
      v-bind:key="route.title"
    >
      <v-icon>{{ route.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { get, sync } from "vuex-pathify";
import IsAuthenticated from "@/utils/validators";
import { DEPARTMENTS_GET } from "@/store/actions/department";

export default {
  data() {
    return {
      routes: [],
      is_authenticated: false,
    };
  },
  methods: {
    async CheckRoutes(routes) {
      let common_routes = [routes[4], routes[3]];
      this.routes = common_routes;
      if (typeof this.$store.getters.profile == "object") {
        switch (this.$store.getters.profile.user_access_level) {
          case "teacher":
            this.routes = [routes[5], routes[6]].concat(common_routes);
            break;
          case "ceo":
            this.routes = routes
            break;
          case "student":
            this.routes = [routes[5], routes[6]].concat(common_routes);
            break;
          case "training_officer":
            this.routes = [routes[5], routes[6], routes[7], routes[8]].concat(
              common_routes
            );
            break;
          case "worker":
            this.routes = [routes[0], routes[1]].concat(common_routes);
            break;
          case "hod":
            this.routes = [routes[0], routes[1]].concat(common_routes);
            break;
          case "depdir":
            this.routes = [routes[0], routes[1]].concat(common_routes);
            break;
          case "admin":
            this.routes = routes;
            break;
        }
        let department = await this.$dispatch(
          DEPARTMENTS_GET,
          "?workers=" + this.$store.getters.profile.id
        );
        if (department.result.length > 0) {
          if (department.result[0].name == "Отдел кадров") {
            this.routes.push(routes[2]);
          }
        } else {
          let resp = await this.$dispatch(
            DEPARTMENTS_GET,
            "?hod=" + this.$store.getters.profile.id
          );
          if (resp.result[0].name == "Отдел кадров") {
            this.routes.push(routes[2]);
          }
        }
      }
    },
    async is_Authenticated() {
      this.is_authenticated = await IsAuthenticated();
    },
  },
  computed: {
    ...get("user", ["dark", "gradient", "image"]),
    ...get("app", ["items", "version"]),
    ...sync("app", ["drawer", "drawerImage", "mini"]),
  },
  created() {
    setTimeout(() => {
      this.CheckRoutes(this.$store.state.app.items);
    }, 1000);
    this.is_Authenticated();
  },
};
</script>

<style scoped>
.bottom-nav-btn {
  padding: 0 !important;
}
</style>
