<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    v-if="width >= 960 && is_authenticated"
    :dark="dark"
    :right="$vuetify.rtl"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    mobile-breakpoint="960"
    app
    width="260"
    color="success"
    disable-route-watcher
  >
    <div class="px-2" v-if="!loading">
      <default-drawer-header class="mb-5" />

      <v-divider class="mx-3 mb-5" />

      <default-list :items="routes" />
    </div>
    <div class="pa-5" v-if="loading">
      <v-skeleton-loader
        class="mx-auto"
        type="text, divider"
        light
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto mt-5"
        type="text"
        light
        v-for="i in 4"
        :key="i"
      ></v-skeleton-loader>
    </div>
    <div class="pt-12" v-if="!loading" />
    <template v-slot:append>
      <default-list
        v-if="!loading"
        :items="[
          {
            title: 'Выйти',
            icon: 'mdi-logout',
            to: '/login/',
            click: Logout,
          },
        ]"
      />
      <div class="pa-5" v-if="loading">
        <v-skeleton-loader
          class="mx-auto mt-5"
          type="text"
          light
        ></v-skeleton-loader>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import IsAuthenticated from "@/utils/validators";
import { get, sync } from "vuex-pathify";
import { DEPARTMENTS_GET } from "@/store/actions/department";

export default {
  data() {
    return {
      routes: [],
      width: 0,
      is_authenticated: false,
      loading: true,
    };
  },
  name: "DefaultDrawer",

  components: {
    DefaultDrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        "./widgets/DrawerHeader"
      ),
    DefaultList: () =>
      import(
        /* webpackChunkName: "default-list" */
        "./List"
      ),
  },

  methods: {
    Logout() {
      localStorage.removeItem("token");
      window.location.reload();
    },
    async CheckRoutes(routes) {
      let common_routes = [routes[4], routes[3]];
      this.routes = common_routes;
      if (typeof this.$store.getters.profile == "object") {
        switch (this.$store.getters.profile.user_access_level) {
          case "teacher":
            this.routes = [routes[5], routes[6]].concat(common_routes);
            break;
          case "ceo":
            this.routes = routes
            break;
          case "student":
            this.routes = [routes[5], routes[6]].concat(common_routes);
            break;
          case "training_officer":
            this.routes = [routes[5], routes[6], routes[7], routes[8]].concat(
              common_routes
            );
            break;
          case "worker":
            this.routes = [routes[0], routes[1]].concat(common_routes);
            break;
          case "hod":
            this.routes = [routes[0], routes[1]].concat(common_routes);
            break;
          case "depdir":
            this.routes = [routes[0], routes[1]].concat(common_routes);
            break;
          case "depdir":
            break;
          case "admin":
            this.routes = routes;
        }
        let department = await this.$dispatch(
          DEPARTMENTS_GET,
          "?workers=" + this.$store.getters.profile.id
        );
        if (department.result.length > 0) {
          if (department.result[0].name == "Отдел кадров") {
            this.routes.push(routes[2]);
          }
        } else {
          let resp = await this.$dispatch(
            DEPARTMENTS_GET,
            "?hod=" + this.$store.getters.profile.id
          );
          if (resp.result[0].name == "Отдел кадров") {
            this.routes.push(routes[2]);
          }
        }
      }
    },
    async is_Authenticated() {
      this.is_authenticated = await IsAuthenticated();
    },
    onResize() {
      this.width = window.innerWidth;
    },
  },

  mounted() {
    this.onResize();
    this.is_Authenticated();
    window.addEventListener("resize", this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...get("user", ["dark", "gradient", "image"]),
    ...get("app", ["items", "version"]),
    ...sync("app", ["drawer", "drawerImage", "mini"]),
  },
  created() {
    this.loading = true;
    this.$root.$refs.Sidebar = this;
    setTimeout(() => {
      this.CheckRoutes(this.$store.state.app.items);
      this.loading = false;
    }, 1000);
  },
};
</script>

<style lang="scss">
#default-drawer {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  max-height: 100% !important;
  .v-list-item {
    margin-bottom: 8px;
  }

  .v-list-item::before,
  .v-list-item::after {
    display: none;
  }

  .v-list-group__header__prepend-icon,
  .v-list-item__icon {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 4px;
  }

  &.v-navigation-drawer--mini-variant {
    .v-list-item {
      justify-content: flex-start !important;
    }
  }
}
</style>
